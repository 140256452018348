import React, { Component } from 'react'

class BlurbProjects extends Component {
  render() {
    return (
    <div className="container">
      <span className="section-header"> Notable <a href="/projects"> Projects </a></span>
      <div>
        <span className="sub-section-title"> <a target="_blank" rel="noopener noreferrer" href="https://github.com/aManNamedTed/arduino/blob/master/speedChessTimer/readme.md">Speed Chess Timer</a></span>
        <ul>
          <li className="sub-section-bullet"> • Speed chess timer that tracks both wins and time. Features an early-reset-win feature for when you absolutely crush your opponent.</li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: Arduino Microcontroller, C++, Circuit Design, Git</li>
        </ul>
      </div>
      <div>
        <span className="sub-section-title"> <a target="_blank" rel="noopener noreferrer" href="http://api.davidamante.com">Projects API</a></span>
        <span className="sub-section-location"> -- (<a target="_blank" rel="noopener noreferrer" href="https://github.com/aManNamedTed/go-api/blob/master/README.md">source code</a>)</span>
        <ul>
          <li className="sub-section-bullet"> • REST API that serves personal project data through an endpoint.</li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: Golang, Heroku, Bash, Git </li>
        </ul>
      </div>
      <div>
        <span className="sub-section-title"> <a target="_blank" rel="noopener noreferrer" href="https://github.com/aManNamedTed/arduino/tree/master/22e60">Password Generator</a> </span>
        <ul>
          <li className="sub-section-bullet"> • Designed and implemented an original base-93 alphabet.</li>
          <li className="sub-section-bullet"> • Encrypts an environmental timestamp into a 32 character password with 2<sup>200</sup> possibilities.</li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: Cryptography, Digital Signal Processing, Arduino Microcontroller, C++, Circuit Design, Git</li>
        </ul>
      </div>
      <div>
        <span className="sub-section-title"> <a href="/">Personal Website</a> </span>
        <span className="sub-section-location"> @ davidamante.com </span>
        <ul>
          <li className="sub-section-bullet"> • Automated new additions to website by scripting the build and deploy process. </li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: React, Javascript, HTML, CSS, Firebase, Bash, Git </li>
        </ul>
      </div>
      <div>
        <span className="sub-section-title"> <a target="_blank" rel="noopener noreferrer" href="https://github.com/aManNamedTed/arduino/tree/master/refreshableBrailleDisplay">Refreshable Braille Display</a> </span>
        <span className="sub-section-location"> @ SD Hacks 2018 </span>
        <ul>
          <li className="sub-section-bullet"> • Driver for refreshable braille display using six servos and an Arduino Uno. </li>
          <li className="sub-section-bullet"> • Expanded functionality post-hackathon by including UEB Braille (advanced English contractions). </li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: Arduino Microcontroller, C++, Circuit Design, Git </li>
        </ul>
      </div>
    </div>
    )
  }
}

export default BlurbProjects
