import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import NavHeader from './components/NavHeader';
import Content from './components/Content';
import Projects from './components/Projects';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function App() {
  return (
  <Router>
      <NavHeader />
      <Switch>
        <Route path="/" exact component={Content} />
        <Route path="/projects" component={Projects} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
  </Router>
  )
}

export default App
