import React from 'react'
import ScrollUpButton from 'react-scroll-up-button'
import BlurbFooter from './BlurbFooter'

function Footer() {
	return (
  <div className="container">
    <ScrollUpButton />
    <BlurbFooter />
  </div>
	)
}

export default Footer
