import React, { Component } from 'react'
import ProjectTechnology from './ProjectTechnology'
import ProjectData from '../data/Projects'
class ProjectItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      projectItem: null
    }
  }

  getYearColor(date) {
    let date_ = new Date(date)
    let year = date_.getFullYear()

    let year_color_bg = {
      2020: "bg-2020",
      2019: "bg-2019",
      2018: "bg-2018",
      2017: "bg-2017",
      2016: "bg-2016",
      2015: "bg-2015"
    }

    return year_color_bg[year]
  }

  stringifyDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"]
    let date_ = new Date(date)
    let month = months[date_.getMonth()]
    let year = date_.getFullYear()

    return <span> {month + " " + year} </span>
  }

  render() {
    const { project } = this.props

    return (
      <div className="col-md-6 card-group mb-3">
        <div className={(this.getYearColor(project.date)) + " card border-dark"}>
          <div className="card-header card-date">{this.stringifyDate(project.date)}</div>
            <div className="card-body">
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <h5 className="title-link card-title">{project.title}</h5>
              </a>
              <p className="card-text project-description">{project.description}</p>
            </div>
            <div className="card-footer text-center">
              <div className="row text-center project-technology">
              {project.technologies.map((technology)=> 
                {return <ProjectTechnology technology={technology}/> })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const projectList = ProjectData
    this.setState({
      isNewestFirst: true,
      projectList: projectList
    })
  }
}          

export default ProjectItem