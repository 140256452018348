import React from 'react'
import face from './../img/headshot-notfound.jpg'
import HeaderImage from './HeaderImage'
import HeaderContact from './HeaderContact'
import BlurbNotFound from './BlurbNotFound'

function NotFound() {
  return (
    <div className="container">
      <HeaderImage image={face} alt_desc={"David Amante looking down in disappointment because you somehow found the 404 error page."}/>
      <HeaderContact />
      <BlurbNotFound />
    </div>
  )
}

export default NotFound
