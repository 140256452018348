import React from 'react'
import face from './../img/headshot1.jpg'
import HeaderImage from './HeaderImage'
import HeaderContact from './HeaderContact'
import BlurbExperience from './BlurbExperience'
import BlurbProjects  from './BlurbProjects'
import BlurbEducation  from './BlurbEducation'

function Content() {
	return (
  <div className="container">
    <HeaderImage image={face} alt_desc={"David Amante grinning at the camera with a computer monitor with code in the background."}/>
    <HeaderContact />
    <BlurbExperience />
    <BlurbProjects />
    <BlurbEducation />
  </div>
	)
}

export default Content