import React from 'react'
import ProjectList from './ProjectList'
import HeaderImage from './HeaderImage'
import HeaderContact from './HeaderContact'
import face from './../img/headshot1.jpg'

const Projects = props => {

  let content = (
  <div className="container">
    <HeaderImage image={face} alt_desc={"David Amante drinking from his coffee cup to prepare for further software project development."} />
    <HeaderContact />
    <ProjectList />
  </div>
  )

  return content
}

export default Projects
