import React, { Component } from 'react'

class BlurbNotFound extends Component {
  render() {
    return (
      <div className="container">
        <div className="sub-header text-center">
          The page you are looking for does not exist!
        </div>
      </div>
    )
  }
}

export default BlurbNotFound