import React, { Component } from 'react'
import { Dropdown, Button } from 'react-bootstrap'
import ProjectData from '../data/Projects.json'
import ProjectItem from './ProjectItem'

class ProjectList extends Component {
  constructor(props) {
    super(props)
    this.toggleSortDate = this.toggleSortDate.bind(this)
    this.toggleSortTitle = this.toggleSortTitle.bind(this)
    this.toggleCategory = this.toggleCategory.bind(this)
    this.toggleTechnology = this.toggleTechnology.bind(this)
    this.state = { 
      projectList: ProjectData,
      dateButtonStatus: "Date: DESC",
      dateIsAscending: false,
      titleButtonStatus: "Title: None",
      titleIsAscending: true,
      categoryButtonStatus: "Category",
      technologyButtonStatus: "Technology"
    }
  }

  getMonth(date) { return new Date(date).getMonth() }
  getYear(date) { return new Date(date).getFullYear() }

  sortByDate() {
    const {projectList} = this.state
    let currentButtonState = this.state.dateButtonStatus
    let newProjectList = projectList

    if(!this.state.dateIsAscending) {
      newProjectList = projectList.sort((a, b) => {
        if(this.getYear(a.date) === this.getYear(b.date)) {
          return this.getMonth(a.date) - this.getMonth(b.date)
        } else {
          return this.getYear(a.date) - this.getYear(b.date)
        }
      })
      currentButtonState = "Date: ASC"
    } else {
      newProjectList = projectList.sort((a, b) => {
        if(this.getYear(b.date) === this.getYear(a.date)) {
          return this.getMonth(b.date) - this.getMonth(a.date)
        } else {
          return this.getYear(b.date) - this.getYear(a.date)
        }
      })
      currentButtonState = "Date: DESC"
    } 
    this.setState({
      dateIsAscending: !this.state.dateIsAscending,
      projectList: newProjectList,
      dateButtonStatus: currentButtonState,
      titleButtonStatus: "Title: None"
    })
  }

  toggleSortDate(event) {
    this.sortByDate()
  }

  sortByTitle() {
    const {projectList} = this.state
    let currentButtonState = this.state.titleButtonStatus
    let newProjectList = projectList

    if(this.state.titleIsAscending) {
      currentButtonState = "Title: ASC"
      newProjectList = projectList.sort((a, b) => {
        if( a.title > b.title) return 1
        else if(b.title > a.title) return -1
        else return 0
      })
    } else {
      currentButtonState = "Title: DESC"
      newProjectList = projectList.sort((a, b) => {
        if( a.title < b.title) return 1
        else if(b.title < a.title) return -1
        else return 0
      })
    } 

    this.setState({
      titleIsAscending: !this.state.titleIsAscending,
      projectList: newProjectList,
      titleButtonStatus: currentButtonState,
      dateButtonStatus: "Date: None"
    })
  }

  toggleSortTitle(event) {
    this.sortByTitle()
  }

  sortByCategory(category) {
    const {projectList} = this.state
    let newProjectList = projectList
    let currentButtonState = category
    let currentDateButtonStatus = "Date: None"

    if(category === "Category") {
      newProjectList = ProjectData.sort((a, b) => {
        if(this.getYear(b.date) === this.getYear(a.date)) {
          return this.getMonth(b.date) - this.getMonth(a.date)
        } else {
          return this.getYear(b.date) - this.getYear(a.date)
        }
      })
      currentDateButtonStatus = "Date: DESC"
    } else {
      newProjectList = ProjectData.filter(project => project.categories.includes(category))
    }

    if(this.state.dateButtonStatus === "Date: DESC") {
      currentDateButtonStatus = "Date: DESC"
    } else if(this.state.dateButtonStatus === "Date: ASC") {
      currentDateButtonStatus = "Date: ASC"
    }

    this.setState({
      projectList: newProjectList,
      categoryButtonStatus: currentButtonState,
      titleButtonStatus: "Title: None",
      dateButtonStatus: currentDateButtonStatus,
      technologyButtonStatus: "Technology"
    })
  }

  toggleCategory(category) {
    this.sortByCategory(category)
  }

  sortByTechnology(technology) {
    const {projectList} = this.state
    let newProjectList = projectList
    let currentButtonState = technology
    let currentDateButtonStatus = "Date: None"

    if(technology === "Technology") {
      newProjectList = ProjectData.sort((a, b) => {
        if(this.getYear(b.date) === this.getYear(a.date)) {
          return this.getMonth(b.date) - this.getMonth(a.date)
        } else {
          return this.getYear(b.date) - this.getYear(a.date)
        }
      })
      currentDateButtonStatus = "Date: DESC"
    } else {
      newProjectList = ProjectData.filter(project => project.technologies.includes(technology))
    }

    if(this.state.dateButtonStatus === "Date: DESC") {
      currentDateButtonStatus = "Date: DESC"
    } else if(this.state.dateButtonStatus === "Date: ASC") {
      currentDateButtonStatus = "Date: ASC"
    }

    this.setState({
      projectList: newProjectList,
      categoryButtonStatus: "Category",
      titleButtonStatus: "Title: None",
      dateButtonStatus: currentDateButtonStatus,
      technologyButtonStatus: currentButtonState
    })
  }

  toggleTechnology(technology) {
    this.sortByTechnology(technology)
  }

  render() { 
    const {projectList} = this.state
    return (
      <div className="container">
        <div className="sort-spacer text-center">
          <Button onClick={this.toggleSortDate} variant="success" className="sort-button-text mb-3"> {this.state.dateButtonStatus} </Button>
          <Dropdown>
            <Dropdown.Toggle className="sort-button-text mb-3" variant="success" id="dropdown-basic"> {this.state.categoryButtonStatus} </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="text-gray sort-button-text" onClick={() => this.toggleCategory("Category")}>Category</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Application")}>Application</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("API")}>API</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Back-End")}>Back-End</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Cloud")}>Cloud</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Data Science")}>Data Science</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Developer Tools")}>Developer Tools</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Embedded Systems")}>Embedded Systems</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Game")}>Game</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Mobile")}>Mobile</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Security")}>Security</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Software")}>Software</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleCategory("Web")}>Web</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className="sort-button-text mb-3" variant="success" id="dropdown-basic"> {this.state.technologyButtonStatus} </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="text-gray sort-button-text" onClick={() => this.toggleTechnology("Technology")}>Technology</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Arduino")}>Arduino</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("C++")}>C++</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("CSS")}>CSS</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Firebase")}>Firebase</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Go")}>Go</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Heroku")}>Heroku</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("HTML")}>HTML</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Java")}>Java</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Javascript")}>Javascript</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Jupyter")}>Jupyter</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("LaTeX")}>LaTeX</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Photoshop")}>Photoshop</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("Python")}>Python</Dropdown.Item>
              <Dropdown.Item className="sort-button-text" onClick={() => this.toggleTechnology("React")}>React</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button onClick={this.toggleSortTitle} variant="success" className="sort-button-text mb-3"> {this.state.titleButtonStatus} </Button>
        </div>
        <div className="container">
          <div className="row text-white">
            {projectList.map((project, index) => { return <ProjectItem project={project}/> })}
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const projectList = ProjectData
    this.setState({
      projectList: projectList,
      dateButtonStatus: "Date: DESC",
      dateIsAscending: false,
      titleButtonStatus: "Title: None",
      titleIsAscending: true,
      categoryButtonStatus: "Category",
      technologyButtonStatus: "Technology" 
    })
  }
}

export default ProjectList
