import React, { Component } from 'react'

class BlurbFooter extends Component {
  render() {
    return (
      <div className="container col-xl-10">
        <div className="text-center footer">
          Copyright © 2017-2021, David Amante. All rights reserved.
        </div>
      </div>
    )
  }
}

export default BlurbFooter