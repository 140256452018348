import React from 'react'
import Octicon, { MailRead, Home, FileBinary } from '@primer/octicons-react'

function HeaderContact() {
  return (
  <div>
    <div className="header text-center"> 
      David Amante
    </div>
    <div className="sub-header text-center"> 
      Software Engineer
    </div>
    <div className="row contact text-center justify-content-center">
      <div className="contact-text">
        <Octicon icon={MailRead} verticalAlign="middle" className="contact-spacer"></Octicon>
        hi@davidamante.com
      </div>
      <div className="contact-text">
        <Octicon icon={Home} verticalAlign="middle" className="contact-spacer"></Octicon>
        <a href="/">davidamante.com</a>
      </div>
      <div className="contact-text">
        <Octicon icon={FileBinary} verticalAlign="middle" className="contact-spacer"></Octicon>
        <a href="https://github.com/aManNamedTed">github.com/aManNamedTed</a>
      </div>
    </div>
  </div>
  )
}

export default HeaderContact;