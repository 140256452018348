import React, { Component } from 'react'

class BlurbEducation extends Component {
  render() {
    return (
    <div className="container">
      <span className="section-header"> Education </span>
      <div>
        <span className="sub-section-title"> Cognitive Science, Neuroscience B.S </span>
        <span className="sub-section-location"> @ UC San Diego </span>
      </div>
      <div className="sub-section-date">
        Sep 2016 - Mar 2019 // San Diego, CA
      </div>
      <ul>
        <li className="sub-section-bullet"> 
          <div>
            • <b>Courses:</b> Object-Oriented Programming, Data Science, 
            Artificial Intelligence, Machine Learning, Discrete Mathematics
          </div>
        </li>
      </ul>
    </div>
    )
  }
}

export default BlurbEducation