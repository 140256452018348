import React, { Component } from 'react'

class HeaderImage extends Component {
  render() {
    return (
      <div className="container text-center">
        <a to href="/"><img src={this.props.image} alt={this.props.alt_desc} className="title-image"/></a>
      </div>
    )
  }
}

export default HeaderImage 