import React, { Component } from 'react'

class BlurbExperience extends Component {
  render() {
    return (
    <div className="container">
      <span className="section-header"> Relevant Experience </span>
      <div>
        <span className="sub-section-location"> <a href="https://trovata.io" target="_blank" rel="noopener noreferrer">Trovata.io</a> -- San Diego, CA</span>
      </div>
      <div className="vl">
        <div>
          <span className="sub-section-title"> Software Engineer </span>
        </div>
        <div className="sub-section-date">
          Aug 2020 - Present
        </div>
        <ul>
          <li className="sub-section-bullet"> • Reduced authentication overhead for real-time processes from O(n) to O(1)</li>
          <li className="sub-section-bullet"> • Collaborated on a cross-departmental team to produce a new core business product</li>
          <li className="sub-section-bullet"> • Consistently autonomous and productive while working with our remote Chicago team</li>
          <li className="sub-section-bullet"> • Support existing integrated data pipelines</li>
          <li className="sub-section-bullet"> • Redesign core platform to allow granular version control </li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: languageY, languageX, languageZ, cloudService(s), PaaS, Git, Bash </li>
        </ul>
        <div>
          <span className="sub-section-title"> Junior Software Engineer </span>
        </div>
        <div className="sub-section-date">
          Oct 2019 - Aug 2020 (11 months)
        </div>
        <ul>
          <li className="sub-section-bullet"> • Designed and implemented an asynchronous event-driven notification system</li>
          <li className="sub-section-bullet"> • Integrated data pipelines into core systems using various cryptographic schemes</li>
          <li className="sub-section-bullet"> • Designed and implemented a proof-of-concept for asynchronous data ingestion, reducing time <br/> complexity from (number of requests * request time) to just (request time) or O(n * m) to O(m).</li>
          <li className="sub-section-bullet"> • Consistently autonomous and productive while working with our remote San Francisco team</li>
          <li className="sub-section-bullet"> • Designed and implemented developer tools that increased developer productivity by up to 90%</li>
          <li className="sub-section-bullet"> • Learned a third programming language to integrate and support a core data provider </li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: languageX, languageY, languageZ, cloudService(s), PaaS, Git, Bash </li>
        </ul>
      </div>
      <div>
        <span className="sub-section-location"> <a href="https://medschool.ucsd.edu/research/aging/Pages/default.aspx" target="_blank" rel="noopener noreferrer">Stein Institute for Research on Aging</a> -- San Diego, CA</span>
      </div>
      <div className="vl">
        <div>
          <span className="sub-section-title"> Junior Programmer </span>
        </div>
        <div className="sub-section-date">
          Feb 2018 - Mar 2019 (12 months)
        </div>
        <ul>
          <li className="sub-section-bullet"> • Reduced new database table sizes by up to 42%. </li>
          <li className="sub-section-bullet"> • Proposed new database schema to reduce table size up to 75%. </li>
          <li className="sub-section-bullet"> • Developed full stack web application prototype in 1 month using .NET and C#. </li>
          <li className="sub-section-bullet"> • <u>Utilized</u>: Microsoft Access, VBA, SQL, C#, ASP.NET, SPSS </li>
        </ul>
      </div>
    </div>
    )
  }
}

export default BlurbExperience