import React, { Component } from 'react'

class ProjectTechnology extends Component {
  getTechnologyColor(technology) {
    let technology_color  = {
      "Arduino": "arduino",
      "C++": "cpp",
      "Cron": "cron",
      "CSS": "css",
      "Firebase": "firebase",
      "Go": "go",
      "Heroku": "heroku",
      "HTML": "html",
      "LaTeX": "latex",
      "Java": "java",
      "Javascript": "javascript",
      "Jupyter": "jupyter",
      "Photoshop": "photoshop",
      "PostgreSQL": "sql",
      "Python": "python",
      "React": "react",
      "SMTP": "smtp",
      "Web Debugging Proxy": "webDebuggingProxy"
    }

    return technology_color[technology]
  }

  render () {
    const {technology} = this.props
    
    return (
      <div className={(this.getTechnologyColor(technology)) + " d-flex justify-content-center cl-sm-4 col-md-4 col-lg-4 card-group card border-dark"}>
        {technology}
      </div>
    )
  }
}

export default ProjectTechnology