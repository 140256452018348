import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import Octicon, { Beaker } from '@primer/octicons-react'

function NavHeader() {
	return (
  <Navbar className="bg-green" expand="lg">
    <Navbar.Brand href="/"><Octicon icon={Beaker} size="medium" verticalAlign="middle" className="white-brand"/></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav navbar-light"/>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="d-md-flex d-block flex-row mx-md-auto mx-0 mt-2 mt-lg-0">
        <Nav.Link href="/projects" className="nav-item text-white l-nav-spacer nav-text"> Projects </Nav.Link>
        <Nav.Link href={process.env.PUBLIC_URL + '/david-amante-resume.pdf'} className="nav-item nav-text text-white r-nav-spacer" target="_blank"> Resume </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
	)
}

export default NavHeader